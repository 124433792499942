import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import "./loading.scss";

export default function Loading() {
  const [progress, setProgress] = useState(0);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prev => {
        const newProgress = prev + Math.floor(Math.random() * 10);
        return newProgress > 100 ? 100 : newProgress;
      });
    }, 150);
    
    return () => clearInterval(interval);
  }, []);
  
  return (
    <section className='loadingPage' id='loading'>
      <div className="particles">
        {Array.from({ length: 25 }).map((_, i) => (
          <div key={i} className="particle"></div>
        ))}
      </div>
      <div className="loading-content">
        <motion.div 
          className="logo-container"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <div className="code-brackets left">{"<"}</div>
          <div className="logo-text">Bagher</div>
          <div className="code-brackets right">{"/>"}</div>
        </motion.div>
        
        <div className="loader-container">
          <motion.div 
            className="progress-container"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.8 }}
          >
            <div className="progress-bar">
              <motion.div 
                className="progress-fill"
                initial={{ width: 0 }}
                animate={{ width: `${progress}%` }}
                transition={{ duration: 0.5 }}
              />
            </div>
            <div className="progress-text">{progress}%</div>
          </motion.div>
          
          <div className="loading-message">
            Building experience<span className="dot-animation">...</span>
          </div>
        </div>
      </div>
    </section>
  );
}
