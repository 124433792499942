import React, { useEffect, useState } from 'react';
import { IoLocationSharp } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
import { IoLogoGithub, IoLogoLinkedin } from "react-icons/io";
import { FaArrowUp } from "react-icons/fa";
import { motion } from "framer-motion";

import './footer.scss';
import { footerLoading } from '../../../../signlas/globle';
import { Variant } from '../../../../utils';

export default function Footer() {
    const currentYear = new Date().getFullYear();
    const [showScrollTop, setShowScrollTop] = useState(false);

    useEffect(() => {
        footerLoading.value = false;

        const handleScroll = () => {
            if (window.scrollY > 500) {
                setShowScrollTop(true);
            } else {
                setShowScrollTop(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <footer className='contact' id='Contact'>
            <div className="footer-content">
                <motion.div
                    className="contact-section"
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                    viewport={{ once: true }}
                >
                    <h2 className="contact-title">Get In Touch</h2>
                    <p className="contact-subtitle">Interested in working together? Feel free to reach out!</p>

                    <div className='social-contact'>
                        <ul className='contact-list'>
                            <motion.li
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true }}
                                variants={Variant('fadeDown', 'tween', 0.5, 0.1)}
                            >
                                <a href="https://maps.app.goo.gl/9mcAszonrJjJwAjs7" target="_blank" rel="noopener noreferrer">
                                    <IoLocationSharp className="icon" />
                                    <span>Stockholm, Sweden</span>
                                </a>
                            </motion.li>
                            <motion.li
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true }}
                                variants={Variant('fadeDown', 'tween', 0.5, 0.2)}
                            >
                                <a href="mailto:Bagherhosseini03@gmail.com" title="Send me an email">
                                    <IoMdMail className="icon" />
                                    <span>Bagherhosseini03@gmail.com</span>
                                </a>
                            </motion.li>
                            <motion.li
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true }}
                                variants={Variant('fadeDown', 'tween', 0.5, 0.3)}
                            >
                                <a href="https://github.com/bagherhosseini" target="_blank" rel="noopener noreferrer">
                                    <IoLogoGithub className="icon" />
                                    <span>GitHub</span>
                                </a>
                            </motion.li>
                            <motion.li
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true }}
                                variants={Variant('fadeDown', 'tween', 0.5, 0.4)}
                            >
                                <a href="https://www.linkedin.com/in/bagherhosseini/" target="_blank" rel="noopener noreferrer">
                                    <IoLogoLinkedin className="icon" />
                                    <span>LinkedIn</span>
                                </a>
                            </motion.li>
                        </ul>
                    </div>
                </motion.div>
            </div>
            {showScrollTop && (
                <motion.button
                    className="scroll-to-top"
                    onClick={scrollToTop}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                >
                    <FaArrowUp />
                </motion.button>
            )}

            <div className="copyright-section">
                <p className='copyright'>
                    <span>Designed & Built by Bagher Hosseini</span>
                    <span className="copyright-year">Copyright &copy; {currentYear}</span>
                </p>
            </div>
        </footer>
    );
}