// Animation variants for Framer Motion
export const Variant = (name, type = "spring", duration = 0.5, delay = 0) => {
  const variants = {
    fadeIn: {
      offscreen: {
        opacity: 0,
      },
      onscreen: {
        opacity: 1,
        transition: {
          type,
          duration,
          delay,
        },
      },
    },
    fadeUp: {
      offscreen: {
        y: 50,
        opacity: 0,
      },
      onscreen: {
        y: 0,
        opacity: 1,
        transition: {
          type,
          duration,
          delay,
        },
      },
    },
    fadeDown: {
      offscreen: {
        y: -50,
        opacity: 0,
      },
      onscreen: {
        y: 0,
        opacity: 1,
        transition: {
          type,
          duration,
          delay,
        },
      },
    },
    fadeLeft: {
      offscreen: {
        x: -100,
        opacity: 0,
      },
      onscreen: {
        x: 0,
        opacity: 1,
        transition: {
          type,
          duration,
          delay,
        },
      },
    },
    fadeRight: {
      offscreen: {
        x: 100,
        opacity: 0,
      },
      onscreen: {
        x: 0,
        opacity: 1,
        transition: {
          type,
          duration,
          delay,
        },
      },
    },
    scaleUp: {
      offscreen: {
        scale: 0.8,
        opacity: 0,
      },
      onscreen: {
        scale: 1,
        opacity: 1,
        transition: {
          type,
          duration,
          delay,
        },
      },
    },
  };

  return variants[name];
};

// Export other utilities here
export { initSmoothScroll } from './smoothScroll';