import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FiExternalLink, FiGithub } from 'react-icons/fi';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

import './project.scss';
import { projects } from '../../../../services';

export default function Project({ id }) {
    const [currentProject, setCurrentProject] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [imageLoaded, setImageLoaded] = useState(false);

    // Determine if image should use "cover" or "contain" based on project name
    const getImageFitClass = (projectName) => {
        // List of projects that should use "cover" instead of "contain"
        const coverStyleProjects = ['Innerweb', 'BlankTech', 'Falakdeh'];
        return coverStyleProjects.includes(projectName) ? 'cover-image' : 'contain-image';
    };

    useEffect(() => {
        const projectData = projects.find((project) => project.id === id);
        setCurrentProject(projectData);
        setCurrentImageIndex(0);
        setIsLoading(false);
        setImageLoaded(false);
    }, [id]);

    const nextImage = () => {
        if (currentProject && currentProject.imgs) {
            setImageLoaded(false);
            setCurrentImageIndex((prev) =>
                prev === currentProject.imgs.length - 1 ? 0 : prev + 1
            );
        }
    };

    const prevImage = () => {
        if (currentProject && currentProject.imgs) {
            setImageLoaded(false);
            setCurrentImageIndex((prev) =>
                prev === 0 ? currentProject.imgs.length - 1 : prev - 1
            );
        }
    };

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    if (isLoading || !currentProject) {
        return (
            <div className="projectPage loading">
                <div className="loader"></div>
            </div>
        );
    }

    return (
        <div className="projectPage">
            <div className="projectContainer">
                <motion.h1
                    className="project-title"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    {currentProject.name}
                </motion.h1>

                <motion.div
                    className="project-tags"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.1 }}
                >
                    <span className="project-lang">{currentProject.lang}</span>
                    {currentProject.status && (
                        <span className="project-status">{currentProject.status}</span>
                    )}
                </motion.div>

                <motion.div
                    className="project-actions"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                >
                    {currentProject.link && (
                        <a
                            href={currentProject.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="action-button demo-link"
                        >
                            <FiExternalLink /> Live Demo
                        </a>
                    )}

                    {currentProject.githubLink && (
                        <a
                            href={currentProject.githubLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="action-button github-link"
                        >
                            <FiGithub /> View Code
                        </a>
                    )}
                </motion.div>

                {currentProject.imgs && currentProject.imgs.length > 0 && (
                    <motion.div
                        className="imgsContainer"
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.3 }}
                    >
                        <AnimatePresence mode="wait">
                            <motion.img
                                key={currentImageIndex}
                                src={currentProject.imgs[currentImageIndex]}
                                alt={`${currentProject.name} screenshot ${currentImageIndex + 1}`}
                                className={`project-image ${getImageFitClass(currentProject.name)}`}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: imageLoaded ? 1 : 0 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.3 }}
                                onLoad={handleImageLoad}
                            />
                        </AnimatePresence>

                        {!imageLoaded && (
                            <div className="image-loading">
                                <div className="loader"></div>
                            </div>
                        )}

                        {currentProject.imgs.length > 1 && (
                            <div className="image-controls">
                                <button
                                    className="img-nav-btn"
                                    onClick={prevImage}
                                    aria-label="Previous image"
                                >
                                    <BsArrowLeft />
                                </button>
                                <button
                                    className="img-nav-btn"
                                    onClick={nextImage}
                                    aria-label="Next image"
                                >
                                    <BsArrowRight />
                                </button>
                            </div>
                        )}
                    </motion.div>
                )}

                <motion.div
                    className="project-description"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                    dangerouslySetInnerHTML={{ __html: currentProject.description }}
                />
            </div>
        </div>
    );
}
