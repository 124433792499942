import React, { useRef, useEffect, useState } from 'react';
import { motion, useScroll, useTransform } from "framer-motion";
import { Canvas } from '@react-three/fiber';
import { useSignalEffect } from '@preact/signals-react';
import { FiArrowDown, FiGithub, FiLinkedin, FiMail } from 'react-icons/fi';
import './main.scss';
import * as page from '../';
import * as features from '../../features';
import { aboutLoading, footerLoading, mainLoading, projectsLoading } from '../../signlas/globle';

export default function Main() {
  const target = useRef(null);
  const about = useRef(null);
  const textRef = useRef(null);
  const cursorRef = useRef(null);
  const [typingComplete, setTypingComplete] = useState(false);
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const iRef = useRef(0);
  const speed = 100;

  // Text animation
  const fullText = 'Welcome to my portfolio! Take a look around and discover my work.';

  const { scrollYProgress } = useScroll({
    target,
    offset: ["start start", "end start"]
  });

  const opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0]);
  const opacityWrapper = useTransform(scrollYProgress, [0, 0.25], [1, 0]);
  const opacityImg = useTransform(scrollYProgress, [0.3, 0.6], [0, 1]);
  const visibilityRoom = useTransform(scrollYProgress, value =>
    value <= 0.5 ? "visible" : "hidden"
  );
  const zIndex = useTransform(scrollYProgress, value =>
    value <= 0.5 ? 1 : -1
  );

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useSignalEffect(() => {
    const typeWriter = () => {
      if (mainLoading.value === false && aboutLoading.value === false && projectsLoading.value === false && footerLoading.value === false) {
        if (iRef.current < fullText.length) {
          textRef.current.textContent += fullText.charAt(iRef.current);
          iRef.current++;
          setTimeout(typeWriter, speed);
        } else {
          setTimeout(() => {
            if (cursorRef.current) {
              cursorRef.current.style.visibility = 'hidden';
            }
          }, 3000);
        }
      }
    };
    typeWriter();
  }, [mainLoading, aboutLoading, projectsLoading, footerLoading]);

  return (
    <section className='mainPage' ref={target}>
      <div className="particles">
        {Array.from({ length: 25 }).map((_, i) => (
          <div key={i} className="particle"></div>
        ))}
      </div>

      <motion.div
        className='hero-wrapper'
        style={{ opacity: opacityWrapper }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <div className='hero-content'>
          <motion.div
            className="hero-text-container"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.8 }}
          >
            <h1 className="hero-title">
              Hey, I'm <span className="name-highlight">{"<Bagher />"}</span>
            </h1>

            <div className="hero-subtitle">
              <p className="typed-text" ref={textRef}></p>
              <span className={`cursor ${typingComplete ? 'blinking' : ''}`} ref={cursorRef}></span>
            </div>

            <div className="social-links">
              <motion.a
                href="https://github.com/yourusername"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                <FiGithub />
              </motion.a>
              <motion.a
                href="https://linkedin.com/in/yourusername"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                <FiLinkedin />
              </motion.a>
              <motion.a
                href="mailto:your.email@example.com"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                <FiMail />
              </motion.a>
            </div>
          </motion.div>
        </div>

        <motion.div
          className='scroll-indicator'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 2.5, duration: 0.8 }}
        >
          <FiArrowDown className="scroll-icon" />
          <div className='scroll-text'>Scroll to explore</div>
        </motion.div>
      </motion.div>

      <motion.div
        className='room-container'
        style={{
          opacity,
          zIndex,
          visibility: visibilityRoom,
        }}
      >
        <Canvas shadows camera={{ position: [0, 2, 5], fov: 60 }}>
          <features.Room target={target} />
        </Canvas>
      </motion.div>

      <motion.div
        className='about-container'
        name="about"
        id='about'
        ref={about}
        style={{
          opacity: opacityImg,
        }}
      >
        <page.About />
      </motion.div>
    </section>
  );
}