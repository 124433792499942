import React, { useEffect } from 'react';
import { useSignalEffect } from '@preact/signals-react';
import { AnimatePresence } from 'framer-motion';
import 'bear-react-carousel/dist/index.css';

import "./styles/app.scss";
import * as page from './pages';
import * as features from './features';
import { mainLoading, aboutLoading, projectsLoading, footerLoading } from "./signlas/globle";

function App() {
  useSignalEffect(() => {
    if (mainLoading.value === false && aboutLoading.value === false && projectsLoading.value === false && footerLoading.value === false) {
      document.getElementById('loading').style.opacity = 0;
      setTimeout(function () {
        document.getElementById('loading').style.visibility = 'hidden';
        document.documentElement.style.overflowY = 'visible';
      }, 500);
    }
  }, [mainLoading, aboutLoading, projectsLoading, footerLoading]);

  // Add scroll behavior for smooth transitions
  useEffect(() => {
    const handleScrollToSection = (e) => {
      // Handle any internal navigation here if needed
      const hash = window.location.hash;
      if (hash) {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    };

    window.addEventListener('load', handleScrollToSection);
    return () => {
      window.removeEventListener('load', handleScrollToSection);
    };
  }, []);

  return (
    <div className='app'>
      <page.Loading />
      <AnimatePresence>
        <page.Main />
        <page.Projects />
        <features.Footer />
      </AnimatePresence>
    </div>
  );
}

export default App;
