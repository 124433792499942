import React, { useEffect, useRef, useState } from 'react';
import { IoClose } from "react-icons/io5";
import { motion, AnimatePresence } from "framer-motion";

import './projects.scss';
import * as features from '../../features';
import { projects } from '../../services';
import { projectsLoading } from '../../signlas/globle';
import { Variant } from '../../utils';

export default function Projects() {
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState(null);
  const [activeCategoryFilter, setActiveCategoryFilter] = useState('All');
  const dialogRef = useRef(null);
  const projectsContainerRef = useRef(null);
  
  // Get unique categories from projects
  const categories = ['All', ...new Set(projects.map(project => project.lang))];

  // Filter projects based on selected category
  const filteredProjects = activeCategoryFilter === 'All' 
    ? projects 
    : projects.filter(project => project.lang === activeCategoryFilter);

  useEffect(() => {
    projectsLoading.value = false;
  }, []);

  const openDialog = (id) => {
    setIsOpen(true);
    setId(id);
    if (dialogRef.current) {
      dialogRef.current.showModal();
      dialogRef.current.classList.remove('closed');
    }
    document.documentElement.style.overflowY = 'hidden';
  };

  const closeDialog = () => {
    setIsOpen(false);
    document.documentElement.style.overflowY = 'visible';
    if (dialogRef.current) {
      dialogRef.current.classList.add('closed');
      setTimeout(() => {
        dialogRef.current.close();
      }, 300);
    }
  };

  // Close dialog when clicking outside
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dialogRef.current && e.target === dialogRef.current) {
        closeDialog();
      }
    };
    
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Close dialog with Escape key
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape' && isOpen) {
        closeDialog();
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  return (
    <section className='projectsPage' id='Project'>
      <div className='allContent'>
        <motion.div 
          className='heading'
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
        >
          <h2>Featured Projects</h2>
          <p>Showcasing my best work and technical expertise</p>
          
          <div className="category-filters">
            {categories.map((category, index) => (
              <motion.button
                key={index}
                className={`filter-btn ${activeCategoryFilter === category ? 'active' : ''}`}
                onClick={() => setActiveCategoryFilter(category)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.1 * index }}
              >
                {category}
              </motion.button>
            ))}
          </div>
        </motion.div>

        <div className='projects' ref={projectsContainerRef}>
          <AnimatePresence mode="wait">
            <motion.div 
              className="projects-grid"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key={activeCategoryFilter}
            >
              {filteredProjects && filteredProjects.slice().reverse().map((item, index) => (
                <motion.div
                  key={index}
                  className='projectBox'
                  onClick={() => openDialog(item.id)}
                  style={{ 
                    originX: 0.5, 
                    originY: 0.5,
                  }}
                  initial="offscreen" 
                  whileInView="onscreen" 
                  viewport={{ once: false, margin: "-100px" }} 
                  variants={Variant('fadeUp', 'tween', 0.5, index * 0.1)}
                  whileHover={{ 
                    scale: 1.02,
                    rotate: item.rotate * 0.5,
                    transition: { duration: 0.3 } 
                  }}
                  whileTap={{ scale: 0.98 }}
                >
                  <div className='projectCon'>
                    <div className='proj-img' style={{ backgroundImage: `url(${item.img})` }}>
                      <div className='overlay' />
                      <div className='projectInfo'>
                        <span className='pro-name'>{item.name}</span>
                        <div className="project-tags">
                          <span className='pro-lang'>{item.lang}</span>
                          {item.status && <span className='pro-status'>{item.status}</span>}
                        </div>
                      </div>
                      <motion.p 
                        className='view'
                        initial={{ opacity: 0.7, x: -10 }}
                        whileHover={{ opacity: 1, x: 0 }}
                      >
                        View Details <span>→</span>
                      </motion.p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          </AnimatePresence>
        </div>

        <dialog ref={dialogRef} className="project-dialog">
          <motion.div 
            className='dialog-content'
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
          >
            <div className='header'>
              <button className='closeBtn' onClick={closeDialog}>
                <IoClose />
              </button>
            </div>
            {id && <features.Project id={id} />}
          </motion.div>
        </dialog>
      </div>
    </section>
  );
}
