import React, { useEffect, useRef } from 'react';
import { motion } from "framer-motion";
import { RoughNotation, RoughNotationGroup } from 'react-rough-notation';
import { FaReact, FaNodeJs, FaDatabase, FaServer, FaTools } from 'react-icons/fa';
import { SiJavascript, SiTypescript, SiNextdotjs, SiExpress, SiMongodb, SiPostgresql, SiFirebase } from 'react-icons/si';

import './about.scss';
import { aboutLoading } from '../../signlas/globle';
import bagher from '../../assets/images/Bagher.jpg';

export default function About() {
    const skillsRef = useRef(null);
    
    useEffect(() => {
        aboutLoading.value = false;
    }, []);

    const frontendSkills = [
        { name: 'React', icon: <FaReact /> },
        { name: 'Next.js', icon: <SiNextdotjs /> },
        { name: 'JavaScript', icon: <SiJavascript /> },
        { name: 'TypeScript', icon: <SiTypescript /> },
        { name: 'HTML/CSS', icon: <FaTools /> },
        { name: 'Tailwind', icon: <FaTools /> },
        { name: 'SCSS', icon: <FaTools /> },
        { name: 'Redux', icon: <FaTools /> },
    ];

    const backendSkills = [
        { name: 'Node.js', icon: <FaNodeJs /> },
        { name: 'Express', icon: <SiExpress /> },
        { name: 'REST API', icon: <FaServer /> },
        { name: 'MongoDB', icon: <SiMongodb /> },
        { name: 'PostgreSQL', icon: <SiPostgresql /> },
        { name: 'Firebase', icon: <SiFirebase /> },
        { name: 'Socket.io', icon: <FaServer /> },
        { name: 'WebRTC', icon: <FaServer /> },
    ];

    const otherSkills = [
        { name: 'Git', icon: <FaTools /> },
        { name: 'Prisma', icon: <FaDatabase /> },
        { name: 'SQL', icon: <FaDatabase /> },
        { name: 'CI/CD', icon: <FaTools /> },
        { name: 'Webflow', icon: <FaTools /> },
        { name: 'WordPress', icon: <FaTools /> },
        { name: 'SquareSpace', icon: <FaTools /> },
        { name: 'Azure', icon: <FaTools /> },
    ];

    return (
        <div className="aboutPage" id="aboutSection">
            <div className="about-container">
                <motion.div 
                    className="about-content"
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                    viewport={{ once: true }}
                >
                    <div className="about-header">
                        <div className="title-container">
                            <h2 className="about-title">About Me</h2>
                            <div className="title-underline"></div>
                        </div>

                        <div className="profile-container">
                            <div className="profile-image-container">
                                <motion.img 
                                    src={bagher} 
                                    alt="Bagher Hosseini" 
                                    className="profile-image"
                                    initial={{ scale: 0.9, opacity: 0 }}
                                    whileInView={{ scale: 1, opacity: 1 }}
                                    transition={{ duration: 0.6 }}
                                    viewport={{ once: true }}
                                />
                                <div className="image-decoration"></div>
                            </div>
                            
                            <div className="terminal-container">
                                <div className="terminal-header">
                                    <div className="terminal-buttons">
                                        <span className="terminal-button close"></span>
                                        <span className="terminal-button minimize"></span>
                                        <span className="terminal-button maximize"></span>
                                    </div>
                                    <div className="terminal-title">developer.profile</div>
                                </div>
                                <div className="terminal-body">
                                    <div className="terminal-line">
                                        <span className="terminal-prompt">$ </span>
                                        <span className="terminal-command">whoami</span>
                                    </div>
                                    <div className="terminal-output">
                                        Bagher Hosseini - Full Stack Developer
                                    </div>
                                    <div className="terminal-line">
                                        <span className="terminal-prompt">$ </span>
                                        <span className="terminal-command">location</span>
                                    </div>
                                    <div className="terminal-output">
                                        Stockholm, Sweden
                                    </div>
                                    <div className="terminal-line">
                                        <span className="terminal-prompt">$ </span>
                                        <span className="terminal-command">experience</span>
                                    </div>
                                    <div className="terminal-output">
                                        3+ years building web applications
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="about-description">
                        <RoughNotationGroup show={true}>
                            <p className="description-text">
                                I'm a <RoughNotation type="highlight" color="#FFD70030" show={true} animationDelay={300}>
                                    full stack web developer
                                </RoughNotation> with expertise in building scalable and high performance web applications. 
                                My technical knowledge spans across <RoughNotation type="highlight" color="#FFD70030" show={true} animationDelay={600}>
                                    modern JavaScript frameworks
                                </RoughNotation>, backend systems, and database management.
                            </p>
                            <p className="description-text">
                                I specialize in creating <RoughNotation type="highlight" color="#FFD70030" show={true} animationDelay={900}>
                                    responsive and intuitive user interfaces
                                </RoughNotation> that deliver exceptional user experiences. My approach combines clean, 
                                efficient code with thoughtful design principles to build solutions that not only look 
                                great but perform seamlessly across all devices.
                            </p>
                            <p className="description-text">
                                Whether working on <RoughNotation type="highlight" color="#FFD70030" show={true} animationDelay={1200}>
                                    frontend development
                                </RoughNotation>, <RoughNotation type="highlight" color="#FFD70030" show={true} animationDelay={1500}>
                                    backend systems
                                </RoughNotation>, or <RoughNotation type="highlight" color="#FFD70030" show={true} animationDelay={1800}>
                                    full stack integration
                                </RoughNotation>, I bring a meticulous eye for detail 
                                and a commitment to writing maintainable, future-proof code.
                            </p>
                        </RoughNotationGroup>
                    </div>

                    <motion.div 
                        className="skills-section"
                        ref={skillsRef}
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, delay: 0.2 }}
                        viewport={{ once: true }}
                    >
                        <h3 className="skills-title">Technical Expertise</h3>
                        
                        <div className="skills-container">
                            <div className="skill-category">
                                <h4 className="category-title">Frontend</h4>
                                <div className="skills-grid">
                                    {frontendSkills.map((skill, index) => (
                                        <motion.div 
                                            className="skill-item"
                                            key={index}
                                            initial={{ opacity: 0, y: 10 }}
                                            whileInView={{ opacity: 1, y: 0 }}
                                            transition={{ duration: 0.3, delay: 0.05 * index }}
                                            viewport={{ once: true }}
                                            whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
                                        >
                                            <div className="skill-icon">{skill.icon}</div>
                                            <span className="skill-name">{skill.name}</span>
                                        </motion.div>
                                    ))}
                                </div>
                            </div>
                            
                            <div className="skill-category">
                                <h4 className="category-title">Backend</h4>
                                <div className="skills-grid">
                                    {backendSkills.map((skill, index) => (
                                        <motion.div 
                                            className="skill-item"
                                            key={index}
                                            initial={{ opacity: 0, y: 10 }}
                                            whileInView={{ opacity: 1, y: 0 }}
                                            transition={{ duration: 0.3, delay: 0.05 * index }}
                                            viewport={{ once: true }}
                                            whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
                                        >
                                            <div className="skill-icon">{skill.icon}</div>
                                            <span className="skill-name">{skill.name}</span>
                                        </motion.div>
                                    ))}
                                </div>
                            </div>
                            
                            <div className="skill-category">
                                <h4 className="category-title">Other Tools</h4>
                                <div className="skills-grid">
                                    {otherSkills.map((skill, index) => (
                                        <motion.div 
                                            className="skill-item"
                                            key={index}
                                            initial={{ opacity: 0, y: 10 }}
                                            whileInView={{ opacity: 1, y: 0 }}
                                            transition={{ duration: 0.3, delay: 0.05 * index }}
                                            viewport={{ once: true }}
                                            whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
                                        >
                                            <div className="skill-icon">{skill.icon}</div>
                                            <span className="skill-name">{skill.name}</span>
                                        </motion.div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            </div>
        </div>
    );
}
